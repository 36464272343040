import { Flex, rem, Space, Text } from "@mantine/core";
import { AppRoutes, Fonts } from "../../models";
import { DateUtils, StringUtils } from "../../utils";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Job } from "../../types";
import { IconLocation, IconMapPin, IconNavigation } from "@tabler/icons-react";

export function JobSummaryCard(props: any) {

    const job: Job = props.job;
    const activeJob: Job | null = useSelector((state: RootState) => state.searchResultPage.activeJob);

    return (
        <>
            <Flex
                direction={"column"}
                bg={job === activeJob ? "#e9e9e9" : "#ffffff"}
                w={"100%"}
                style={{
                    borderRadius: "6px",
                    cursor: "pointer",
                    border: job === activeJob ? "1px solid #000000" : "1px solid #cfcfcf",
                    boxShadow: job === activeJob ? "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px" : "",
                }}
                justify={"space-between"}
                p={16}
                mb={8}
            >
                <Text
                    fw={"bold"}
                    style={{ fontFamily: Fonts.INTER, fontSize: 16 }}
                >
                    {job.title}
                </Text>
                <Space h={12} />

                <Text
                    c={"#4d4d4d"}
                >
                    <IconMapPin  style={{height: rem(18), width: rem(18), marginRight: 6}} />{job.city}
                </Text>
                <Text
                    c={"#4d4d4d"}
                >
                    Posted by {job.advertiser_name}
                </Text>

                <Space h={12} />

                <Text
                    c={"#4d4d4d"}
                    ta={"justify"}
                >
                    {(StringUtils.stripHtmlTags(job.description)).substring(0, 120)}...
                </Text>

                <Space h={12} />
                <Flex
                    justify={"space-between"}
                >
                    <Text
                        c={"#4d4d4d"}
                        size="sm"
                    >
                        Posted {DateUtils.getTimeFromNow(job.post_date)}
                    </Text>
                    <Link
                        to={`/${AppRoutes.JOB}/${job.country}/${job.title}/${job.sender_reference}`}
                        style={{ color: "#4d4d4d" }}
                    >
                        More
                    </Link>
                </Flex>
            </Flex>
        </>
    )
}