import { Anchor, Button, Divider, Flex, Loader, Pagination, rem, ScrollArea, Space, Text } from "@mantine/core";
import { JobSummaryCard } from "../../components/jobSummaryCard/jobSummaryCard.component";
import { AppRoutes, Fonts, MAX_SALARY, MIN_SALARY, RawText } from "../../models";
import { IconArrowUpRight, IconClock, IconCoin, IconMapPin } from "@tabler/icons-react";
import store, { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { resetActiveJob, setActiveJob } from "../../store/searchResultPageSlice";
import { useEffect, useState } from "react";
import { DateUtils, StringUtils } from "../../utils";
import parse from 'html-react-parser';
import { useSearchParams } from "react-router-dom";
import { JobService } from "../../services/jobsService";
import { Country, Job, SearchFilterQuery } from "../../types";
import { SearchFilterComponent } from "../../components";
import { useMediaQuery } from "@mantine/hooks";
import { LocationResolverService } from "../../services/locationResolverService";
import { titleService } from "../../services";
import { title } from "process";

export function JobSearchResult() {

    const [loading, setLoading] = useState<boolean>(true);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [totalJobsCount, setTotalJobsCount] = useState<number>(0);
    const [activePage, setActivePage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const pageSize: number = 20;

    const [searchParams, setSearchParams] = useSearchParams();

    const activeJob: Job | null = useSelector((state: RootState) => state.searchResultPage.activeJob);
    let country: Country | null = useSelector((state: RootState) => state.userMetaData.clientCountry);

    const isDesktop = useMediaQuery('(min-width: 900px)');

    const jobService: JobService = new JobService();
    const locationResolverService: LocationResolverService = new LocationResolverService();

    useEffect(() => {
        setActivePage(1);
        titleService.setTitle(`Jobs of ${country?.fullName} in ${DateUtils.getCurrentMonthName()} ${DateUtils.getCurrentYear()}`)
        fetchJobs();
    }, [searchParams])

    useEffect(() => {
        fetchJobs();
    }, [activePage])

    useEffect(() => {
        window.scroll(0, 0);
        if (jobs.length) {
            store.dispatch(setActiveJob(jobs[0]));
        }
        else {
            store.dispatch(resetActiveJob());
        }
    }, [jobs]);

    async function fetchJobs() {
        setLoading(true);
        let keyword: string = searchParams.has("keyword") ? searchParams.get("keyword") as string : "";
        let locations: string[] = searchParams.getAll("locations");
        let distance: string = searchParams.has("distance") ? searchParams.get("distance") as string : "";
        let employmentTypes: string[] = searchParams.getAll("employmentTypes");
        let categories: string[] = searchParams.getAll("categories");
        let employers: string[] = searchParams.getAll("employers");
        let salaryMin: number = Number(searchParams.get("salaryMin") ? searchParams.get("salaryMin") : MIN_SALARY);
        let salaryMax: number = Number(searchParams.get("salaryMax") ? searchParams.get("salaryMax") : MAX_SALARY);

        let regions: string[] = locations.map((loc: string) => loc.split(",")[1].trim());
        if (regions.length) {
            regions.push("Not Specified");
        }
        let cities: string[] = locations.map((loc: string) => loc.split(",")[0].trim());
        if (cities.length) {
            cities.push("Not Specified");
        }

        let countries: string[] = country ? [country.countryName] : [];
        let radius = 0, latitudeAnchor = 0, longitudeAnchor = 0;
        if (distance && distance.length && cities.length <= 2 && cities[0] != "Not Specified" && country) {
            radius = parseInt(distance.split("+")[0]);
            const city = await locationResolverService.searchCityByName(country.countryName, regions[0], cities[0]);
            if (city) {
                latitudeAnchor = city.latitude;
                longitudeAnchor = city.longitude;
            }
        }

        const searchFilterQuery: SearchFilterQuery = {
            keyword: keyword as string,
            countries: countries,
            regions: regions,
            cities: cities,
            categories: categories,
            employers: employers,
            salaryMinimum: salaryMin,
            salaryMaximum: salaryMax,
            radius: radius,
            latitudeAnchor: latitudeAnchor,
            longitudeAnchor: longitudeAnchor,
            distanceUnit: "mi",
            employmentTypes: employmentTypes,
            ageUnixTimestamp: 0,
            limit: pageSize,
            offset: ((activePage - 1) * pageSize)
        };

        console.log(JSON.stringify(searchFilterQuery, null, 2));

        const response = await jobService.findJobs(searchFilterQuery);
        setJobs(response.jobs);
        setTotalJobsCount(response.count);
        setTotalPages(Math.ceil(response.count / pageSize))
        setLoading(false);
    }

    function markJobActive(job: Job) {
        store.dispatch(setActiveJob(job));
    }

    return (
        <Flex
            justify={"center"}
            align={"center"}
            direction={"column"}
            style={{ fontFamily: Fonts.INTER }}
        >
            <SearchFilterComponent loading={loading} />

            {loading ?
                <Flex
                    align={"center"}
                    justify={"center"}
                    h={450}
                >
                    <Loader color="black" size="md" type="bars" />
                </Flex>
                :
                <></>
            }

            {
                !loading && jobs.length > 0 ?
                    <Flex w={isDesktop ? "80%" : "100%"} mb={48}>
                        <Flex direction={"column"} p={8} w={isDesktop ? "30%" : "100%"}>
                            <Text fw={500} size="lg" mx={16} mb={16}>{totalJobsCount} jobs found</Text>
                            <Flex
                                bg={"#ffffff"}
                                style={{
                                    borderRight: "0px solid #cfcfcf",
                                    overflowY: isDesktop ? "scroll" : "visible",
                                    maxHeight: isDesktop ? 900 : ""
                                }}
                            >
                                <Flex direction={"column"} mx={16} >
                                    {
                                        jobs.map((record, i) =>
                                            <Flex onClick={() => markJobActive(record)}>
                                                <JobSummaryCard
                                                    key={i}
                                                    job={record}
                                                />
                                            </Flex>
                                        )}
                                </Flex>
                            </Flex>

                            <Flex justify={"center"} my={24}>
                                <Pagination total={totalPages} color="gray" size="sm" value={activePage} onChange={setActivePage} />
                            </Flex>
                        </Flex>

                        {isDesktop &&
                            <>

                                {activeJob ?
                                    <ScrollArea.Autosize
                                        bg={"#f8f8f8"}
                                        mah={900}
                                        style={{ border: "", borderRadius: 8 }}
                                        scrollbarSize={3}
                                        type="never"
                                        mt={50}
                                        mr={8}
                                        py={20}
                                        pl={20}
                                        w={"70%"}
                                    >
                                        <Flex
                                            direction={"column"}
                                            bg={"#fffff"}
                                            style={{ borderRadius: 8 }}
                                            pr={20}
                                        >
                                            <Flex>
                                                <Anchor href={`${AppRoutes.JOB}/${activeJob.jobsrmine_url}`} target="_blank" underline="never">
                                                    <Text
                                                        fw={"bold"}
                                                        style={{ fontSize: 32 }}
                                                        td={"underline"}
                                                        mb={16}
                                                        c={"black"}
                                                    >
                                                        {StringUtils.toCaptilize(activeJob.title)}
                                                    </Text>
                                                </Anchor>
                                                <IconArrowUpRight size={20} />
                                            </Flex>

                                            <Flex w={"100%"} justify={"space-between"}>
                                                <Flex direction={"column"}>
                                                    <Flex>
                                                        <IconMapPin style={{ height: rem(20), width: rem(20) }} />
                                                        <Space w={12} />
                                                        <Text
                                                            fw={500}
                                                            c={"#4d4d4d"}
                                                        >
                                                            Location: {StringUtils.toCaptilize(activeJob.city)}
                                                        </Text>
                                                    </Flex>

                                                    <Flex>
                                                        <IconCoin style={{ height: rem(20), width: rem(20) }} />
                                                        <Space w={12} />
                                                        <Text
                                                            fw={500}
                                                            c={"#4d4d4d"}
                                                        >
                                                            Salary: {country?.currencySymbol}{activeJob.salary_minimum} - {country?.currencySymbol}{activeJob.salary_maximum} {activeJob.salary_currency} {activeJob.salary_period}
                                                        </Text>
                                                    </Flex>

                                                    <Flex>
                                                        <IconClock style={{ height: rem(20), width: rem(20) }} />
                                                        <Space w={12} />
                                                        <Text
                                                            fw={500}
                                                            c={"#4d4d4d"}
                                                        >
                                                            {StringUtils.toCaptilize(activeJob.employment_type)}
                                                        </Text>
                                                    </Flex>
                                                    <Space h={12} />
                                                    <Flex>
                                                        <Text
                                                            fw={500}
                                                            c={"#4d4d4d"}
                                                        >
                                                            Posted by: {StringUtils.toCaptilize(activeJob.advertiser_name)}
                                                        </Text>
                                                    </Flex>
                                                    <Flex>
                                                        <Text
                                                            fw={500}
                                                            c={"#4d4d4d"}
                                                        >
                                                            Posted: {DateUtils.getTimeFromNow(activeJob.post_date)}
                                                        </Text>
                                                    </Flex>

                                                    <Flex
                                                        my={12}
                                                        align={"center"}
                                                    >
                                                        <Anchor href={`${AppRoutes.JOB}/${activeJob.jobsrmine_url}`} target="_blank" c={"#ffffff"} size="sm" fw={600}>
                                                            <Button color="black" w={120} h={45} radius={"md"}>
                                                                Quick Apply
                                                            </Button>
                                                        </Anchor>
                                                    </Flex>
                                                </Flex>
                                                <Flex px={50}>
                                                    {activeJob.logo_url &&
                                                        <img width={"200px"} height={"100px"} src={activeJob.logo_url} />
                                                    }
                                                </Flex>
                                            </Flex>
                                            <Divider my={12} />

                                            <Text
                                                c={"#4d4d4d"}
                                                fw={500}
                                                my={6}
                                            >
                                                {parse(activeJob.description)}
                                            </Text>
                                            <Divider my={12} />
                                            <Flex
                                                my={12}
                                                justify={"space-between"}
                                            >
                                                <Flex></Flex>
                                                <Anchor href={`${AppRoutes.JOB}/${activeJob.jobsrmine_url}`} c={"#ffffff"} size="sm" fw={600} target="_blank">
                                                    <Button color="black" w={120} h={45} radius={"md"}>
                                                        Quick Apply
                                                    </Button>
                                                </Anchor>
                                            </Flex>
                                        </Flex>
                                    </ScrollArea.Autosize>
                                    :
                                    <></>
                                }

                            </>
                        }

                    </Flex>
                    :
                    <></>
            }

            {!loading && jobs.length === 0 ?
                <Flex justify={"center"} align={"center"} h={600} direction={"column"} w={"60%"}>
                    <Text ta="center" style={{ fontSize: 32, fontFamily: Fonts.INTER }} mb={8} >
                        There are currently no jobs matching your criteria.
                    </Text>
                    <Text ta={"center"}>
                        You may want to widen your search by removing one of your filters.
                        To ensure you don't miss relevant jobs as soon as they’re advertised,
                    </Text>
                    <Text mb={12} ta="center">
                        set up a Job Alert for jobs matching these criteria.
                    </Text>
                    <Button color="black" disabled>Create Alert (comming soon)</Button>
                </Flex> :
                <></>
            }
        </Flex>
    )
}