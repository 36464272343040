import { createSlice, PayloadAction, PayloadActionCreator } from "@reduxjs/toolkit";

export type SearchFilterSlice = {
    keyword: string;
    locations: string[];
    employmentTypes: string[];
    distance: string;
    categories: string[];
    salaryMin: string;
    salaryMax: string;
};

const searchFilterSliceState: SearchFilterSlice = {
    keyword: "",
    locations: [],
    employmentTypes: [],
    distance: "",
    categories: [],
    salaryMin: "",
    salaryMax: ""
};

export const searchFilterSlice = createSlice({
    name: "searchFilterSlice",

    initialState: searchFilterSliceState,
    reducers: {
        setKeyword(state: SearchFilterSlice, action: PayloadAction<string>) {
            state.keyword = action.payload;
            return state;
        },
        resetKeyword(state: SearchFilterSlice) {
            state.keyword = "";
            return state;
        },
        setLocations(state: SearchFilterSlice, action: PayloadAction<string[]>) {
            state.locations = action.payload;
            return state;
        },
        resetLocation(state: SearchFilterSlice) {
            state.locations = [];
            return state;
        },
        setEmploymentType(state: SearchFilterSlice, action: PayloadAction<string[]>) {
            state.employmentTypes = action.payload;
            return state;
        },
        resetEmploymentType(state: SearchFilterSlice) {
            state.employmentTypes = [];
            return state;
        },
        setDistance(state: SearchFilterSlice, action: PayloadAction<string>) {
            state.distance = action.payload;
            return state;
        },
        resetDistance(state: SearchFilterSlice) {
            state.distance = "";
            return state;
        },
        setCategories(state: SearchFilterSlice, action: PayloadAction<string[]>) {
            state.categories = action.payload;
            return state;
        },
        resetCategories(state: SearchFilterSlice) {
            state.categories = [];
            return state;
        },
        setSalaryMin(state: SearchFilterSlice, action: PayloadAction<string>) {
            state.salaryMin = action.payload;
            return state;
        },
        resetSalaryMin(state: SearchFilterSlice) {
            state.salaryMin = "";
            return state;
        },
        setSalaryMax(state: SearchFilterSlice, action: PayloadAction<string>) {
            state.salaryMax = action.payload;
            return state;
        },
        resetSalaryMax(state: SearchFilterSlice) {
            state.salaryMax = "";
            return state;
        }
    }
});

export const {
    setKeyword,
    resetKeyword,
    setLocations,
    resetLocation,
    setEmploymentType,
    resetEmploymentType,
    setDistance,
    resetDistance,
    setCategories,
    resetCategories,
    setSalaryMin,
    resetSalaryMin,
    setSalaryMax,
    resetSalaryMax
} = searchFilterSlice.actions;

export default searchFilterSlice.reducer;