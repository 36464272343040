import { ActionIcon, Button, Flex, Loader, Menu, RangeSlider, Select, Space, Text, TextInput } from "@mantine/core";
import { IconAdjustmentsHorizontal, IconClearAll, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useForm } from '@mantine/form';
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppRoutes, MAX_SALARY, MIN_SALARY } from "../../models";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Location } from "../../types";
import { MaxDisplayedItemsDropdown } from "../maxDisplayedItemsDropdown/maxDisplayedItemsDropdown";
import { useMediaQuery } from "@mantine/hooks";

export function SearchFilterComponent(props: any) {

    const loading: boolean = props.loading;

    const [expanded, setExpanded] = useState<boolean>(false);
    const [availableSearchLocations, setAvailableSearchLocations] = useState<string[]>([]);
    const [resetFlag, setResetFlag] = useState<boolean>(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width: 970px)');

    let availableJobCategories: string[] = useSelector((state: RootState) => state.userMetaData.availableJobCategories);
    let availableEmploymentTypes: string[] = useSelector((state: RootState) => state.userMetaData.availableEmploymentTypes);
    let clientAvailableLocations: Location[] = useSelector((state: RootState) => state.userMetaData.clientAvailableLocations);

    const searchFilterForm = useForm({
        initialValues: {
            keyword: searchParams.has("keyword") ? searchParams.get("keyword") as string : "",
            locations: searchParams.has("locations") ? searchParams.getAll("locations") as string[] : [],
            employmentTypes: searchParams.has("employmentTypes") ? searchParams.getAll("employmentTypes") as string[] : [],
            distance: searchParams.has("distance") ? searchParams.get("distance") as string : null,
            categories: searchParams.has("categories") ? searchParams.getAll("categories") as string[] : [],
            salaryMin: searchParams.has("salaryMin") ? searchParams.get("salaryMin") as string : MIN_SALARY.toString(),
            salaryMax: searchParams.has("salaryMax") ? searchParams.get("salaryMax") as string : MAX_SALARY.toString(),
        },
        validate: {},
    });

    useEffect(() => {
        let locations: string[] = clientAvailableLocations.map((loc: Location) => `${loc.cityName}, ${loc.regionName}`);
        setAvailableSearchLocations(locations);
        console.log("SearchFilter: ", searchFilterForm.getTransformedValues());
    }, [clientAvailableLocations]);

    async function searchJobs() {
        navigate(AppRoutes.JOB_SEARCH);
        let searchOptions: any = {
            locations: searchFilterForm.getTransformedValues().locations,
            employmentTypes: searchFilterForm.getTransformedValues().employmentTypes,
            categories: searchFilterForm.getTransformedValues().categories,
        };
        if (searchFilterForm.getTransformedValues().distance && searchFilterForm.getTransformedValues().distance?.length) {
            searchOptions = { ...searchOptions, distance: searchFilterForm.getTransformedValues().distance }
        }
        if (searchFilterForm.getTransformedValues().keyword.length) {
            searchOptions = { ...searchOptions, keyword: searchFilterForm.getTransformedValues().keyword }
        }
        if (searchFilterForm.getTransformedValues().salaryMin !== MIN_SALARY.toString()) {
            searchOptions = { ...searchOptions, salaryMin: searchFilterForm.getTransformedValues().salaryMin }
        }
        if (searchFilterForm.getTransformedValues().salaryMax !== MAX_SALARY.toString()) {
            searchOptions = { ...searchOptions, salaryMax: searchFilterForm.getTransformedValues().salaryMax }
        }
        setSearchParams(searchOptions);
    }

    async function resetSearchFilter() {
        searchFilterForm.setFieldValue("keyword", "");
        searchFilterForm.setFieldValue("locations", []);
        searchFilterForm.setFieldValue("distance", null);
        searchFilterForm.setFieldValue("employmentTypes", []);
        searchFilterForm.setFieldValue("categories", []);
        searchFilterForm.setFieldValue("salaryMin", MIN_SALARY.toString());
        searchFilterForm.setFieldValue("salaryMax", MAX_SALARY.toString());
        setResetFlag((prev) => !prev);
    }

    return (
        <form onSubmit={searchFilterForm.onSubmit(searchJobs)}>
            <Flex style={{ border: "2px solid black", borderRadius: 8 }} p={40} m={40}>
                <Flex direction={"column"}>
                    <Flex w="100%" wrap={"wrap"}>
                        <TextInput
                            size="md"
                            w={300}
                            placeholder="Job title, skill or keyword"
                            {...searchFilterForm.getInputProps("keyword")}
                            my={6}
                        />
                        <Space w={8} />
                        <Flex w={300} my={6}>
                            <MaxDisplayedItemsDropdown
                                placeholder="Search Location"
                                items={availableSearchLocations}
                                label="Location"
                                width={300}
                                defaultValues={searchFilterForm.getTransformedValues().locations}
                                onValueChange={(values: string[]) => { searchFilterForm.setFieldValue("locations", values) }}
                                resetFlag={resetFlag}
                            />
                        </Flex>
                        <Space w={8} />
                        <Flex my={6} w={200}>
                            <Select
                                placeholder="Distance"
                                data={['10+ miles', "20+ miles", "30+ miles", "50+ miles", "70+ miles", "90+ miles", "150+ miles", "200+ miles"]}
                                size="md"
                                w={"100%"}
                                defaultValue={searchFilterForm.getTransformedValues().distance}
                                {...searchFilterForm.getInputProps("distance")}
                            />
                        </Flex>
                    </Flex>
                    {expanded &&
                        <Flex w="100%" wrap={"wrap"}>
                            <MaxDisplayedItemsDropdown
                                placeholder="Search Employment type"
                                items={availableEmploymentTypes}
                                label="Employment Type"
                                width={280}
                                defaultValues={searchFilterForm.getTransformedValues().employmentTypes}
                                onValueChange={(values: string[]) => { searchFilterForm.setFieldValue("employmentTypes", values) }}
                                resetFlag={resetFlag}
                            />
                            <Space w={8} />
                            <MaxDisplayedItemsDropdown
                                placeholder="Search Industry"
                                items={availableJobCategories}
                                label="Industry"
                                width={280}
                                defaultValues={searchFilterForm.getTransformedValues().categories}
                                onValueChange={(values: string[]) => { searchFilterForm.setFieldValue("categories", values) }}
                                resetFlag={resetFlag}
                            />
                            <Space w={8} />
                            <Menu shadow="md">
                                <Menu.Target>
                                    <Button variant="transparent" style={{ border: "1px solid #cfcfcf" }} size="md">
                                        <Text fw={"normal"} size="sm" c={"#4d4d4d"}>
                                            Salary ${searchFilterForm.getTransformedValues().salaryMin} - ${searchFilterForm.getTransformedValues().salaryMax}
                                        </Text>
                                    </Button>
                                </Menu.Target>

                                <Menu.Dropdown h={80}>
                                    <Menu.Label>
                                        <Text fw={500} c={"#4d4d4d"}>
                                            Salary ${searchFilterForm.getTransformedValues().salaryMin} - ${searchFilterForm.getTransformedValues().salaryMax}
                                        </Text>
                                    </Menu.Label>
                                    <Menu.Item>
                                        <RangeSlider
                                            w={170}
                                            size={"sm"}
                                            color={"#000000"}
                                            minRange={100000}
                                            min={MIN_SALARY}
                                            max={MAX_SALARY}
                                            step={100}
                                            defaultValue={[Number(searchFilterForm.getTransformedValues().salaryMin), Number(searchFilterForm.getTransformedValues().salaryMax)]}
                                            onChangeEnd={(value) => {
                                                searchFilterForm.setFieldValue("salaryMin", value[0] + "");
                                                searchFilterForm.setFieldValue("salaryMax", value[1] + "");
                                            }}
                                        />
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                            {/* <Space w={8} />
                            <Select
                                placeholder="Posted on"
                                w={200}
                                size="md"
                            /> */}
                        </Flex>
                    }
                </Flex>
                <Space w={8} />
                <Flex direction={"column"}>
                    <Flex my={6}>
                        <Button
                            color="black"
                            h={38} radius={"sm"}
                            type="submit"
                        >
                            {loading ?
                                <Flex
                                    align={"center"}>
                                    <Loader color="white" size="sm" type="bars" />
                                </Flex>
                                :
                                <Text
                                    size="sm"
                                >
                                    Find jobs
                                </Text>
                            }
                        </Button>
                        <Space
                            w={10}
                        />
                        {
                            matches &&
                            <ActionIcon
                                variant={expanded ? "light" : "default"}
                                color="black"
                                h={38}
                                w={50}
                                mr={10}
                                aria-label="Settings"
                                onClick={() => setExpanded(!expanded)}
                            >
                                <IconAdjustmentsHorizontal
                                    style={{ width: '70%', height: '70%' }}
                                    stroke={1.5}
                                />
                            </ActionIcon>
                        }
                    </Flex>
                    {expanded &&
                        <Flex my={6}>
                            <Button variant="light" onClick={() => resetSearchFilter()}>
                                <IconClearAll style={{ width: '50%', height: '50%', stroke: "2.0" }} />
                                <Text ml={4}>clear</Text>
                            </Button>
                        </Flex>
                    }
                </Flex>
            </Flex>
        </form>
    )
}